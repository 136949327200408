<template>
  <loader v-bind="{ loading }" text="Loading Addresses">
    <columns v-if="!$root.isPassiveUser()">
      <column class="is-narrow">
        <router-link :to="{
          name: 'create-location'
        }">Create Location</router-link>
      </column>
    </columns>

    <columns>
      <column>
        <action-button :working="downloading" @click="downloadExcel" left-icon="download" class="is-small is-rounded">
          Excel
        </action-button>
      </column>
    </columns>

    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="location in locations.data" :key="location.id">
              <columns>
                <column>
                  <router-link :to="{
                    name: 'location-manager',
                    params: {
                      location: location.uuid
                    }
                  }">
                    {{ location.name }}
                  </router-link>
                  <p>
                    <small class="has-text-grey">Updated {{ location.updated_at | asCalendar }}</small>
                  </p>
                </column>
                <column class="is-narrow">
                  <index-info 
                    icon="building-flag" 
                    label="Branches" 
                    :info="location.branches.length"
                  />
                </column>
              </columns>
          </div>
        </div>
        <pager :pageable="locations" context="Location" jump-controls @nav="goToPage" />
      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import download from 'downloadjs'
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    filters: {
      search: ''
    },
    locations: {
      data: []
    }
  }),

  created() {
    if (this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadLocations()
  },

  methods: {
    loadLocations() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.locations = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'customer-locations',
        params: {
          customer: this.customer.uuid
        },
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        params: {
          customer: this.customer.uuid
        },
        name: 'customer-locations',
      });
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/customers/${this.customer.uuid}/locations/download-xls`, ({ data }) => {
        download(data, `${this.customer.name}-locations.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    }
  },

  computed: {
    ...mapGetters('customer', [
      'customer'
    ])
  },

  watch: {
    '$route': 'loadLocations'
  }

}
</script>