var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Addresses"}},'loader',{ loading: _vm.loading },false),[(!_vm.$root.isPassiveUser())?_c('columns',[_c('column',{staticClass:"is-narrow"},[_c('router-link',{attrs:{"to":{
        name: 'create-location'
      }}},[_vm._v("Create Location")])],1)],1):_vm._e(),_c('columns',[_c('column',[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"working":_vm.downloading,"left-icon":"download"},on:{"click":_vm.downloadExcel}},[_vm._v(" Excel ")])],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.locations.data),function(location){return _c('div',{key:location.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{attrs:{"to":{
                  name: 'location-manager',
                  params: {
                    location: location.uuid
                  }
                }}},[_vm._v(" "+_vm._s(location.name)+" ")]),_c('p',[_c('small',{staticClass:"has-text-grey"},[_vm._v("Updated "+_vm._s(_vm._f("asCalendar")(location.updated_at)))])])],1),_c('column',{staticClass:"is-narrow"},[_c('index-info',{attrs:{"icon":"building-flag","label":"Branches","info":location.branches.length}})],1)],1)],1)}),0),_c('pager',{attrs:{"pageable":_vm.locations,"context":"Location","jump-controls":""},on:{"nav":_vm.goToPage}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }